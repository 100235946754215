@keyframes layer-animation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes layer-animation-reverse {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.layer {
  animation: layer-animation 0.4s;
}

.layer-close {
  animation: layer-animation-reverse 0.4s;
}
